import {
  CallEndIcon,
  Divider,
  ErrorIcon,
  ExclamationCircleIcon,
  Flex,
  FlexItem,
  Text,
  Tooltip,
} from '@fluentui/react-northstar';
import { Fragment } from 'react';
import TeamsCall from 'FEATURES/TeamsCall/TeamsCall';
import FullScreenLoader from 'COMPONENTS/FullScreenLoader/FullScreenLoader';
import FullScreenWarning from 'COMPONENTS/FullScreenWarning/FullScreenWarning';
import CaptionInstructions from 'COMPONENTS/captionInstructions/CaptionInstructions';
import CaptionerStatus from 'COMPONENTS/captionerStatus/CaptionerStatus';
import CaptionControls from 'COMPONENTS/captionControls/CaptionControls';
import CountDownTimer from 'COMPONENTS/countDownTimer/CountDownTimer';
import MeetingInformation from 'COMPONENTS/meetingInformation/MeetingInformation';
import CaptionerFooter from 'COMPONENTS/captionerFooter/CaptionerFooter';
import NoNetworkOverlay from 'COMPONENTS/noNetworkOverlay/NoNetworkOverlay';
import SubscriptionDirections from 'COMPONENTS/subscriptionDirections/SubscriptionDirections';

// Constant
import {
  AUDIO_KEYWORD,
  CAPTIONER_CONNECTING_STATUS,
  CAPTIONER_EVENT,
  CAPTIONER_LANGUAGE_RESOURCE,
  CAPTIONER_MESSAGES,
  ENDCALL_REASON,
  LANGUAGE_PREFIX,
  PUBSUB_MESSAGE_TYPES,
  SERVER_EVENT,
  TIME_INTERVAL,
} from 'CONSTANTS/captionerConstants';
import {
  CALL_STATUS,
  CAPTIONER_STATUS,
  CONNECTION_STATUS,
  SNACKBAR_TYPE,
  MIC_PERMISSION_STATUS,
} from 'CONSTANTS/enums';
import { STATUS_CODE } from 'CONSTANTS/apiConstants';

// Hooks
import { useSelector } from 'react-redux';
import { useEffect, useState, useRef } from 'react';
import useCaptioner from 'HOOKS/useCaptioner';
import useTeamsCall from 'HOOKS/useTeamsCall';
import useMessages from 'HOOKS/useMessages';
import useActions from 'HOOKS/useActions';
import useSnackbar from 'HOOKS/useSnackbar';

// Resource
import AkouoLogo from 'COMPONENTS/AkouoLogo';

// Service
import logger from 'SERVICES/logger';
import { useTranslation } from 'react-i18next';
import PubSubClient from 'SERVICES/PubSubClient';

// Store
import { teamsCallSelector } from 'STORE/teamsCallSlice';
import { meetingActions, meetingSelector } from 'STORE/meetingSlice';
import { appActions } from 'STORE/appSlice';

import 'FEATURES/captioner/Captioner.css';

/**
 * Home Page or Captioner Page
 * this components renders the Meeting Details and Captioner status
 */
const Captioner = () => {
  const { t } = useTranslation(CAPTIONER_LANGUAGE_RESOURCE, LANGUAGE_PREFIX);
  const showSnackbar = useSnackbar();

  // constants for meeting details and and subscribtion details handling
  const { authorization, loading, subcriptionLoading, subcriptionFetchError } = useCaptioner();

  // constants and functions to handle ACS call features
  const {
    captionsData,
    activeSpeaker,
    sessionTime,
    noParticipantsPresent,
    captionsStarted,
    startTeamsCaptions,
    stopTeamsCaptions,
    disconnectTeamsCall,
    MuteControlCheckBox,
  } = useTeamsCall();

  // functions and constants to handel messages recieved on webSocket
  const {
    handleTeamsSpeakerLanguage,
    handleWsMessages,
    handleServerMessage,
    joinTeamsMeetingRequest,
  } = useMessages();

  const callState = useSelector(teamsCallSelector.callState);
  const callEnded = useSelector(teamsCallSelector.callEnded);
  const isCaptionerActive = useSelector(teamsCallSelector.isCaptionerActive);
  const meetingDetails = useSelector(meetingSelector.meetingDetails);
  const subscriptionDetails = useSelector(meetingSelector.subscriptionDetails);
  const wsClient = useSelector(meetingSelector.selectWsClientObj);
  const meetingParicipants = useSelector(meetingSelector.selectParticipant);

  const { isFreeTrial } = subscriptionDetails;

  const [timeLeft, setTimeLeft] = useState(0);

  // State variable for socketClient Error
  const [socketClientError, setSocketClientError] = useState(false);
  // State variable for joinCall Error
  const [joinCallError, setJoinCallError] = useState(false);

  const [loadTeamsCall, setLoadTeamsCall] = useState(false);

  const [isFreeTrialExpired, setIsFreeTrialExpired] = useState(false);

  // timeOut variable for storing timeout ID
  const freeTrialTimeOut = useRef<NodeJS.Timeout | null>(null);
  const subscriptionErrorText = useRef<string>('');

  const { setConnectionStatus } = useActions(appActions);
  const { setWsClient } = useActions(meetingActions);

  // storing subscription id
  const [connectionSubId, setConnectionSubId] = useState<any>('');
  const [teamsSpeakerChangeSubId, setTeamsSpeakerChangeSubId] = useState<any>('');
  const [messageSubId, setMessageSubId] = useState<any>('');
  const [disconnectedSubId, setDisconnectedSubId] = useState<any>('');
  const [micPermissionStatus, setMicPermissionStatus] = useState<string>('');

  // start captions
  const startCaptions = () => {
    // IIFE to start captions and send message to group and server
    (async () => {
      try {
        await startTeamsCaptions();
      } catch (error) {
        logger.error('Error: Unable to start captions', error);
        showSnackbar({
          message: t('START_CAPTIONS_ERROR'),
          type: SNACKBAR_TYPE.DANGER,
        });
        throw error;
      }
    })();
  };

  // stop captions
  const stopCaptions = () => {
    // IIFE to stop captions and send message to group and server
    (async () => {
      try {
        await stopTeamsCaptions();
      } catch (error) {
        logger.error('Error: Unable to stop captions: ', error);
        showSnackbar({
          message: t('STOP_CAPTIONS_ERROR'),
          type: SNACKBAR_TYPE.DANGER,
        });
        throw error;
      }
    })();
  };

  // handle mic permission change status from browser
  const handleMicPermissionChange = (permissionStatus: string) => {
    setMicPermissionStatus(permissionStatus);
  };

  // add online / offline listner
  const addConnectionListener = () => {
    // offline listner
    window.addEventListener(CONNECTION_STATUS.OFFLINE, () => {
      setConnectionStatus(CONNECTION_STATUS.OFFLINE);
    });
    // online listner
    window.addEventListener(CONNECTION_STATUS.ONLINE, () => {
      setConnectionStatus(CONNECTION_STATUS.ONLINE);
    });
  };

  // remove online / offline listner
  const removeConnectionListener = () => {
    window.removeEventListener(CONNECTION_STATUS.OFFLINE, () => {
      setConnectionStatus(CONNECTION_STATUS.OFFLINE);
    });
    window.removeEventListener(CONNECTION_STATUS.ONLINE, () => {
      setConnectionStatus(CONNECTION_STATUS.ONLINE);
    });
  };

  // cleanup function
  const cleanup = () => {
    // IIFE for cleanup
    (async () => {
      // unsubscribing the events
      wsClient?.unsubscribe(PubSubClient?.WS_EVENT?.WS_CONNECTED, connectionSubId);
      logger.info(`unsubscribed to ${PubSubClient?.WS_EVENT?.WS_CONNECTED}`);
      wsClient?.unsubscribe(
        PubSubClient?.WS_EVENT?.MST_SPEAKING_LANGUAGE_CHANGED,
        teamsSpeakerChangeSubId
      );
      logger.info(`unsubscribed to ${PubSubClient?.WS_EVENT?.MST_SPEAKING_LANGUAGE_CHANGED}`);
      wsClient?.unsubscribe(PubSubClient?.WS_EVENT?.WS_MESSAGE, messageSubId);
      logger.info(`unsubscribed to ${PubSubClient?.WS_EVENT?.WS_MESSAGE}`);
      wsClient?.unsubscribe(PubSubClient?.WS_EVENT?.WS_DISCONNECTED, disconnectedSubId);
      logger.info(`unsubscribed to ${PubSubClient?.WS_EVENT?.WS_DISCONNECTED}`);
      // websocket client cleanup
      await wsClient?.cleanup();
    })();
  };

  // To send captions into the call
  useEffect(() => {
    if (captionsData) {
      // send captions in the group
      (async () => {
        await wsClient?.sendMessageToGroup(
          meetingDetails?.meetingId,
          {
            captionsData: {
              speaker: captionsData.speaker,
              captions: captionsData.captions,
            },
          },
          PUBSUB_MESSAGE_TYPES.JSON
        );

        try {
          // send captions to server
          const dataToSend = [];
          for (const languageCaption in captionsData.captions) {
            dataToSend.push({
              'akouo_Job@odata.bind': `akouo_jobs(${meetingDetails.jobDataverseId})`,
              akouo_text: captionsData.captions[languageCaption],
              akouo_languagecode: languageCaption,
              akouo_timestamp: captionsData.timestamp,
              akouo_speakername: captionsData.speaker,
              akouo_speakeremail: captionsData.speakerEmail,
            });
          }
          await wsClient?.sendMessageToServer(
            SERVER_EVENT.SAVE_CAPTIONS,
            {
              captionsData: dataToSend,
            },
            PUBSUB_MESSAGE_TYPES.JSON
          );
        } catch (error) {
          logger.debug('Error: Unable to send data to server', error);
          showSnackbar({
            message: t('SEND_CAPTIONS_ERROR'),
            type: SNACKBAR_TYPE.DANGER,
          });
        }
      })();
    }
  }, [captionsData, activeSpeaker]);

  // To send message to server
  useEffect(() => {
    if (callEnded === undefined) return;
    // sending message to server to stop captions
    (async () => {
      await wsClient?.sendMessageToServer(
        SERVER_EVENT.CAPTIONS_ENDED,
        {
          captionsEvent: CAPTIONER_EVENT.CAPTION_STOPPED,
        },
        PUBSUB_MESSAGE_TYPES.JSON
      );
    })();
    // checking for call end reason
    switch (callEnded) {
      // Meeting Ended by organizer
      case ENDCALL_REASON.CALL_ENDED:
        (async () => {
          // message for server cleanup
          await wsClient?.sendMessageToServer(
            SERVER_EVENT.MEETING_ENDED,
            {
              message: CAPTIONER_MESSAGES.LEFT_MEETING,
            },
            PUBSUB_MESSAGE_TYPES.JSON
          );
          // cleanup
          await cleanup();
        })();
        break;
      // Call hangup when free trial ends
      // Or last participant left in meeting
      case ENDCALL_REASON.HANGUP_CALL:
        (async () => {
          // removing timeOut
          if (freeTrialTimeOut.current) {
            clearTimeout(freeTrialTimeOut.current);
          }
          // message for server cleanup
          await wsClient?.sendMessageToServer(
            SERVER_EVENT.MEETING_ENDED,
            {
              message: CAPTIONER_MESSAGES.LEFT_MEETING,
            },
            PUBSUB_MESSAGE_TYPES.JSON
          );
          await cleanup();
        })();
        break;
      case ENDCALL_REASON.LOCAL_CAEANUP:
        // Just local cleanup
        (async () => {
          await cleanup();
        })();
        break;
      case ENDCALL_REASON.REMOVED_FROM_MEETING:
        // Removed from meeting
        (async () => {
          // message for server cleanup
          await wsClient?.sendMessageToServer(
            SERVER_EVENT.REMOVED_FROM_MEETING,
            {
              message: CAPTIONER_MESSAGES.REMOVED_FROM_MEETING,
            },
            PUBSUB_MESSAGE_TYPES.JSON
          );
          await cleanup();
        })();
        break;
      case ENDCALL_REASON.CALL_DISCONNECTED:
        // Call got disconnected, possibly because of network failure
        (async () => {
          await cleanup();
        })();
        break;
      case ENDCALL_REASON.CALL_REJECTED:
        // Remove form lobby
        (async () => {
          // message for server cleanup
          await wsClient?.sendMessageToServer(
            SERVER_EVENT.REMOVED_FROM_MEETING,
            {
              message: CAPTIONER_MESSAGES.REMOVED_FROM_MEETING,
            },
            PUBSUB_MESSAGE_TYPES.JSON
          );
          await cleanup();
        })();
        break;
      default:
        // Just local cleanup
        (async () => {
          // cleanup
          await cleanup();
        })();
    }
  }, [callEnded]);

  // To handle captioner connecting status
  useEffect(() => {
    if (callState) {
      const audios = document.querySelectorAll(AUDIO_KEYWORD);
      if (callState === CALL_STATUS.CONNECTING && audios.length) {
        // muting call audio element
        audios.forEach((audioElement) => {
          audioElement.muted = true;
        });
      }
      // sending callStatus to group
      (async () => {
        if (!callEnded) {
          await wsClient?.sendMessageToGroup(
            meetingDetails?.meetingId,
            {
              callStatus: {
                status: callState,
              },
            },
            PUBSUB_MESSAGE_TYPES.JSON
          );
        }
      })();
    }
    // sending acknowledgement message to server
    if (callState === CAPTIONER_CONNECTING_STATUS.CONNECTED) {
      // IIFE sending message to server
      (async () => {
        await wsClient?.sendMessageToServer(
          SERVER_EVENT.JOINED_MEETING,
          {
            message: CAPTIONER_MESSAGES.MEETING_JOINED,
          },
          PUBSUB_MESSAGE_TYPES.JSON
        );
      })();
    }
  }, [callState]);

  // Checking for call Status and sending message that captions have started
  useEffect(() => {
    if (callState === CALL_STATUS.CONNECTED && isCaptionerActive) {
      // IIFE for sending message to group
      (async () => {
        try {
          await wsClient?.sendMessageToGroup(
            meetingDetails?.meetingId,
            {
              captionerStatus: {
                status: CAPTIONER_STATUS.CONNECTED,
              },
            },
            PUBSUB_MESSAGE_TYPES.JSON
          );
        } catch (error) {
          logger.error('Error: Unable to send captioner status');
          showSnackbar({
            message: t('START_CAPTIONS_ERROR'),
            type: SNACKBAR_TYPE.DANGER,
          });
        }
      })();
    }
  }, [meetingParicipants]);

  // creating webSocket client and subscribing to events
  useEffect(() => {
    if (!meetingDetails.meetingId) return;

    // IIFE for creating PubsubClient
    (async () => {
      try {
        const webSocketClient = new PubSubClient();
        await webSocketClient.init(meetingDetails?.pubsubClient?.url);
        // storing WS_CONNECTED event subscription id
        setConnectionSubId(
          // subscribing to WS_CONNECTED event
          webSocketClient.subscribe(PubSubClient.WS_EVENT.WS_CONNECTED, (eventData: any) => {
            logger.info(eventData);
            // WS_CONNECTED message body
          })
        );
        // storing CAPTIONS event subscription id
        await setTeamsSpeakerChangeSubId(
          // subscribing to CAPTIONS event
          webSocketClient.subscribe(
            PubSubClient.WS_EVENT.MST_SPEAKING_LANGUAGE_CHANGED,
            (eventData: any) => {
              logger.info(eventData);
              handleTeamsSpeakerLanguage(eventData);
            }
          )
        );
        // storing WS_MESSAGE event subscription id
        await setMessageSubId(
          // subscribing to WS_MESSAGE event
          webSocketClient.subscribe(PubSubClient.WS_EVENT.WS_MESSAGE, (eventData: any) => {
            if (eventData.serverMessage) handleServerMessage(eventData);
            handleWsMessages(eventData);
          })
        );

        // storing CAPTIONER_STATUS event subscription id
        await setDisconnectedSubId(
          // subscribing to CAPTIONER_STATUS event
          webSocketClient.subscribe(PubSubClient.WS_EVENT.WS_DISCONNECTED, (eventData: any) => {
            logger.info(eventData);
          })
        );

        // joining group
        await webSocketClient.joinGroup(meetingDetails.meetingId);

        // storing socket client
        setWsClient(webSocketClient);
        // set Error false to hide error rendered on page
        setSocketClientError(false);
        // set loadTeamsCall to join the Teams meeting
        setLoadTeamsCall(true);
      } catch (error) {
        logger.error('unable to create client', error);
        // set Error True to render it on page
        setSocketClientError(true);
      }
    })();
  }, [meetingDetails]);

  useEffect(() => {
    //
    if (subcriptionFetchError) {
      switch (subcriptionFetchError) {
        case STATUS_CODE.NOT_FOUND:
          subscriptionErrorText.current = t('NO_SUBSCRIPTION');
          break;
        case STATUS_CODE.FORBIDDEN:
          subscriptionErrorText.current = t('FREE_TRIAL_EXPIRED');
          break;
        default:
          subscriptionErrorText.current = t('FETCH_SUBSCRIPTION_ERROR');
      }
      // websocket cleanup
      // No pubsub connection is required when we dont have subscription, hence cleaning up
      cleanup();
    }
  }, [subcriptionFetchError]);

  // Set time left when subscription details are fetched
  useEffect(() => {
    if (subscriptionDetails) setTimeLeft(subscriptionDetails?.timeLeftInMinutes);
  }, [subscriptionDetails]);

  // setting up updated time for after every session
  useEffect(() => {
    if (sessionTime) {
      setTimeLeft((previousTimeLeft) => previousTimeLeft - sessionTime);
    }
  }, [sessionTime]);

  // sending message to server when captions feature starts or stops
  useEffect(() => {
    if (isCaptionerActive) {
      // IIFE for sending message using Pubsub
      (async () => {
        try {
          await Promise.all([
            // sending message to group
            wsClient?.sendMessageToGroup(
              meetingDetails?.meetingId,
              {
                captionerStatus: {
                  status: CAPTIONER_STATUS.CONNECTED,
                },
              },
              PUBSUB_MESSAGE_TYPES.JSON
            ),
            // sending message to server
            wsClient?.sendMessageToServer(
              SERVER_EVENT.CAPTIONS_STARTED,
              {
                captionsEvent: CAPTIONER_EVENT.CAPTION_STARTED,
              },
              PUBSUB_MESSAGE_TYPES.JSON
            ),
          ]);
        } catch (error) {
          // error sending message to server and group
          logger.error('Error: unable to start captions', error);
          showSnackbar({
            message: t('SOMETHING_WENT_WRONG'),
            type: SNACKBAR_TYPE.DANGER,
          });
        }
      })();
    } else {
      // IIFE for sending message using Pubsub
      (async () => {
        try {
          await Promise.all([
            wsClient?.sendMessageToGroup(
              meetingDetails?.meetingId,
              {
                captionerStatus: {
                  status: CAPTIONER_STATUS.DISCONNECTED,
                },
              },
              PUBSUB_MESSAGE_TYPES.JSON
            ),
            // sending message to server
            wsClient?.sendMessageToServer(
              SERVER_EVENT.CAPTIONS_ENDED,
              {
                captionsEvent: CAPTIONER_EVENT.CAPTION_STOPPED,
              },
              PUBSUB_MESSAGE_TYPES.JSON
            ),
          ]);
        } catch (error) {
          // error sending message to server and group
          logger.error('Error: unable to stop captions', error);
          showSnackbar({
            message: t('SOMETHING_WENT_WRONG'),
            type: SNACKBAR_TYPE.DANGER,
          });
        }
      })();
    }
  }, [isCaptionerActive]);

  // using listners to check app connection status
  useEffect(() => {
    let permissionStatus: PermissionStatus;
    // adding listners to check connection status
    addConnectionListener();
    // mic permission listner
    (async () => {
      const permissionStatus = await navigator.permissions.query({
        name: 'microphone' as PermissionName,
      });
      setMicPermissionStatus(permissionStatus.state);
      permissionStatus.onchange = () => handleMicPermissionChange(permissionStatus.state);
    })();
    // using distructor to remove listners
    return () => {
      removeConnectionListener();
      permissionStatus.onchange = null;
    };
  }, []);

  // hangup call for Free Subscription
  const stopFreeTrial = () => {
    stopCaptions();
    // time out is used to stop render conflict with countDownTimer component
    freeTrialTimeOut.current = setTimeout(() => {
      // IIFE to disconnect call and send message to server for cleanup
      (async () => {
        try {
          // call disconnect
          await disconnectTeamsCall();
          // sending message to server for cleanup
          await wsClient?.sendMessageToServer(
            SERVER_EVENT.MEETING_ENDED,
            {
              message: CAPTIONER_MESSAGES.LEFT_MEETING,
            },
            PUBSUB_MESSAGE_TYPES.JSON
          );
        } catch (error) {
          logger.error('Error: Unable to disconnect call:', error);
          showSnackbar({
            message: t('CALL_HANGUP_ERROR'),
            type: SNACKBAR_TYPE.DANGER,
          });
        }
      })();
    }, TIME_INTERVAL.HUNDRED_MS);
    setIsFreeTrialExpired(true);
  };

  // if Error during joining call Warning will be rendered
  if (joinCallError) return <FullScreenWarning label={t('JOIN_CALL_ERROR')} />;
  // if erroe during web socket creation then render error
  if (socketClientError) return <FullScreenWarning label={t('WEB_SOCKET_ERROR')} />;
  // if not Authorised then show warning
  if (!authorization.isAuthorized) return <FullScreenWarning label={authorization.authLabel} />;
  // if loading show loading
  if (loading) return <FullScreenLoader label={t('LOADING_MEETING_INFORMATION')} />;
  // callEnded
  if (callEnded || callEnded === ENDCALL_REASON.HANGUP_CALL)
    return (
      <Flex fill hAlign="center" vAlign="center" gap="gap.medium">
        <CallEndIcon size="larger" className="call-end-icon" />
        {isFreeTrialExpired ? (
          <Text size="large" content={t('NO_CREDITS_LEFT')} />
        ) : (
          <Text
            size="large"
            content={
              callEnded === ENDCALL_REASON.CALL_ENDED || noParticipantsPresent
                ? t('MEETING_ENDED')
                : t('CALL_DICSONNECT_MESSAGE')
            }
          />
        )}
      </Flex>
    );

  return (
    <Flex column fill hAlign="center" className="captioner-interface" gap="gap.small">
      {loadTeamsCall && !subcriptionFetchError && (
        <TeamsCall joinTeamsMeetingRequest={joinTeamsMeetingRequest} setError={setJoinCallError} />
      )}
      <NoNetworkOverlay />
      <FlexItem align="start" className="captioner-header">
        <Flex className="w-100" space="between">
          <MeetingInformation />
          <FlexItem>
            <Flex gap="gap.smaller" vAlign="center">
              <Text content="Powered by" size="small" weight="semibold" />
              <AkouoLogo width="100px" />
            </Flex>
          </FlexItem>
        </Flex>
      </FlexItem>
      <Divider color="grey" className="divider w-100" />
      <FlexItem className="captioner-body">
        <Flex fill gap="gap.medium">
          <Flex fill column gap="gap.medium">
            {micPermissionStatus !== MIC_PERMISSION_STATUS.GRANTED && <CaptionInstructions />}
            <Flex vAlign="center" gap="gap.smaller">
              <MuteControlCheckBox />
              <Text content={t('SINGLE_SPEAKER_MODE')} size="medium" weight="semibold" />
              <Tooltip content={t('MUTE_CONTROL_TOOLTIP')}>
                <ExclamationCircleIcon outline />
              </Tooltip>
            </Flex>
            {!captionsStarted && (
              <Flex vAlign="center" gap="gap.smaller">
                <Text content={t('SINGLE_SPEAKER_MODE_INFO_TEXT')} size="medium" />
              </Flex>
            )}
          </Flex>
          <Flex id="divider">
            <Divider vertical color="grey" className="divider" />
          </Flex>
          <Flex fill column id="caption-information">
            {subcriptionLoading ? (
              <FullScreenLoader label={t('LOADING_SUBSCRIPTION_INFORMATION')} />
            ) : (
              <Fragment>
                <SubscriptionDirections />
                {subcriptionFetchError ? (
                  <Flex fill vAlign="center" hAlign="center" column gap="gap.medium">
                    <Flex column hAlign="center" gap="gap.small">
                      <ErrorIcon size="largest" />
                      <Text content={subscriptionErrorText.current} size="large" align="center" />
                    </Flex>
                    <Flex column hAlign="center">
                      <Text
                        content="Thank you for trying Akouo Captions for MS Teams!"
                        align="center"
                      />
                      <Text content={t('SUBSCRIPTION_ERROR_TEXT')} align="center" />
                    </Flex>
                  </Flex>
                ) : (
                  <Fragment>
                    {isFreeTrial && (
                      <Flex vAlign="center" gap="gap.smaller">
                        <ExclamationCircleIcon outline size="small" />
                        <Text content={t('FREE_SUB_TIME_LIMIT')} weight="semibold" />
                        <Text
                          content={`${Math.round(timeLeft)} ${
                            timeLeft > 1 ? t('MINUTES') : t('MINUTE')
                          }`}
                          important
                        />
                      </Flex>
                    )}
                    <Flex fill hAlign="center" vAlign="center" className="captioner-status">
                      <div className="captioner-status-layout">
                        <Flex column hAlign="center" gap="gap.medium" fill>
                          <CaptionerStatus />
                          <CaptionControls
                            startCaptions={startCaptions}
                            stopCaptions={stopCaptions}
                            isDisabled={isFreeTrial && isFreeTrialExpired}
                          />
                        </Flex>
                        <Flex className="caption-countdown" fill hAlign="center">
                          {isCaptionerActive && !callEnded && (
                            <Fragment>
                              {isFreeTrial && !isFreeTrialExpired && (
                                <CountDownTimer
                                  startTime={Math.round(timeLeft * 60)}
                                  isCountDown={true}
                                  stopFreeTrial={stopFreeTrial}
                                />
                              )}
                              {!isFreeTrial && (
                                <CountDownTimer
                                  startTime={0}
                                  isCountDown={false}
                                  stopFreeTrial={stopFreeTrial}
                                />
                              )}
                            </Fragment>
                          )}
                        </Flex>
                      </div>
                    </Flex>
                  </Fragment>
                )}
              </Fragment>
            )}
          </Flex>
        </Flex>
      </FlexItem>
      <FlexItem align="end" className="captioner-footer">
        <CaptionerFooter />
      </FlexItem>
    </Flex>
  );
};

export default Captioner;
