export const enum CAPTIONER_AVTAR_STATUS_COLOR {
  CONNECTED = 'green',
  WARNING = 'yellow',
  ERROR = 'red',
  DISCONNECTED = 'grey',
}

export const enum CALL_STATUS {
  CONNECTING = 'Connecting',
  IN_LOBBY = 'InLobby',
  CONNECTED = 'Connected',
  DISCONNECTING = 'Disconnecting',
  DISCONNECTED = 'Disconnected',
}

export const enum CAPTIONER_STATUS {
  CONNECTED = 'Connected',
  DISCONNECTED = 'Disconnected',
}

export const enum CONNECTION_STATUS {
  ONLINE = 'online',
  OFFLINE = 'offline',
}

export const enum SNACKBAR_TYPE {
  INFO = 'info',
  DANGER = 'danger',
  SUCCESS = 'success',
  WARNING = 'warning',
};

export const enum SNACKBAR_DURATION {
  SHORT = 1000,
  MEDIUM = 2000,
  LONG = 4000,
};

export const enum MIC_PERMISSION_STATUS {
  GRANTED = 'granted',
  DENIED = 'denied',
  PROMPT = 'prompt',
};
